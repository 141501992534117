<style>
tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.1);
}
</style>
<template>
  <v-container>
    <v-form
      ref="wagerSearchForm"
      class="mx-auto"
      style="max-width: 600px;"
    >
      <v-row>
        <v-col>

          <v-menu
            v-model="datepickers.startdate.open"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="form.startdate"
                label="Start Date"
                prepend-icon="mdi-calendar"
                readonly
                clearable
                hide-details
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="form.startdate"
              no-title
              @input="datepickers.startdate.open = false"
            ></v-date-picker>
          </v-menu>

        </v-col>
        <v-col>

          <v-menu
            v-model="datepickers.enddate.open"
            :disabled="!form.startdate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="form.enddate"
                label="End Date"
                prepend-icon="mdi-calendar"
                readonly
                clearable
                hide-details
                :disabled="!form.startdate"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="form.enddate"
              no-title
              :min="form.startdate"
              @input="datepickers.enddate.open = false"
            ></v-date-picker>
          </v-menu>

        </v-col>
      </v-row>
      <v-row>
        <v-col>

          <v-text-field
            label="Search"
            hide-details
            prepend-icon="mdi-magnify"
            clearable
            v-model="form.searchtext"
            @keypress.enter="search"
          >
            <template v-slot:append>
              <v-btn
                depressed
                tile
                small
                color="primary"
                class="ma-0"
                @click="search"
              >
                Search
              </v-btn>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
    </v-form>

    <v-tabs
      v-model="tab"
      centered
      class="mt-5"
      :key="refreshTabs"
    >
      <v-tab
        v-for="t in tabs"
        :key="t.name"
      >
        {{ t.name }}
        <span v-if="t.wagers && t.wagers.length > 0"> ({{ t.wagers.length }})</span>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <!-- Open -->
      <v-tab-item
        v-for="t in tabs"
        :key="t.name"
      >
        <v-data-table
          dense
          single-expand
          :expanded.sync="t.expanded"
          show-expand
          :headers="headers"
          :items="t.wagers"
          :footer-props="{ itemsPerPageOptions:[ -1, 15, 25, 50 ] }"
          :header-props="{ sortIcon: null }"
          :items-per-page="25"
          item-key="id"
          class="elevation-1 mt-0"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>{{ t.name }} Wagers</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
          </template>

          <template v-slot:item="{ item, expand, isExpanded }">
            <tr>
              <td class="text-no-wrap">{{ item.betstatus }}</td>
              <td>{{ item.gametime }}</td>
              <td>{{ item.away }}</td>
              <td>{{ item.home }}</td>
              <td>{{ item.wager_id }}</td>
              <td class="text-right">${{ item.offeredbyamount }}</td>
              <td>{{ item.teamofferer }} {{ showSpread((item.offeredbyteam_id === item.hometeam_id) ? item.lineamount : item.lineamount * -1 ) }}</td>
              <td :class="(item.winner_id) ? ((item.winner_id === item.offeredby_id) ? 'winner' : 'loser') : ''">
                {{ item.offerer }}<br>
                {{ item.offerernickname }}
                ({{ item.offeredby_id }})
                (${{ item.offererlimit }})
              </td>
              <td class="text-right"><span v-if="item.takenbyamount">${{ item.takenbyamount }}</span></td>
              <td>{{ item.teamtaker }} {{ showSpread((item.takenbyteam_id === item.hometeam_id) ? item.lineamount : item.lineamount * -1 ) }}</td>
              <td :class="(item.winner_id) ? ((item.winner_id === item.takenby_id) ? 'winner' : 'loser') : ''">
                <span v-if="item.takenby_id">
                  {{ item.taker }}<br>
                  {{ item.takernickname }}
                  ({{ item.takenby_id }})
                  (${{ item.takerlimit }})
                </span>
              </td>
              <td
                class="px-0"
                style="width: 32px;"
              >
                <v-icon @click="expand(!isExpanded)">{{ (isExpanded) ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
              </td>
            </tr>
          </template>

          <template v-slot:expanded-item="{ headers, item }">
            <tr>
              <td></td>
              <td :colspan="headers.length - 2">
                <v-row>
                  <v-col>
                    <h2>Wager #{{ item.wager_id }}: {{ item.description }}</h2>
                    <h3>${{ item.offeredbyamount }} to win ${{ item.takenbyamount }}</h3>
                    Game ID: {{ item.game_id }} - {{ item.league }} - {{ item.gamestatus }}<br>
                    {{ item.awayteamname }} @ {{ item.hometeamname }}<br>
                    Start: {{ item.gametime }}<br>
                    <span v-if="item.gameendtime !== null">Final: {{ item.gameendtime }}<br></span>
                    <span v-if="item.hometeamscore !== null"><strong>{{ item.awayteamscore }} @ {{ item.hometeamscore }}</strong></span>
                    <span v-if="item.paymentsent && item.paymentreceived">
                      <WagerPayments
                        :wager="item"
                        action="Update Payment Info"
                        @update="search"
                      ></WagerPayments>
                    </span>
                  </v-col>
                  <v-col>
                    <BettorInfo
                      :wager="item"
                      type="offer"
                      @update="search"
                    ></BettorInfo>
                  </v-col>
                  <v-col v-if="item.takenby_id > 0">
                    <BettorInfo
                      :wager="item"
                      type="taker"
                      @update="search"
                    ></BettorInfo>
                  </v-col>
                </v-row>
              </td>
              <td></td>
            </tr>
            <tr>
              <td :colspan="headers.length">
                <v-row>
                  <v-col cols="6" class="py-0 px-0 black--text">
                    <v-row
                      class="ma-0"
                      @click="clickexpander(2)"
                    >
                      <v-col cols="1">
                        <span>
                          <v-icon color="black">{{ innerexpand == 1 ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                        </span>
                      </v-col>
                      <v-col cols="1">
                        Chat
                      </v-col>
                    </v-row>

                    <v-expand-transition>
                      <v-card
                        block
                        v-show="innerexpand == 2"
                        class="pa-3 overflow-y-auto"
                        max-height="600"
                      >
                        <chat-log :wager_id="item.wager_id"></chat-log>
                      </v-card>
                    </v-expand-transition>
                  </v-col>
                  <v-col
                    cols="6"
                    class="py-0 px-0 black--text"
                  >
                    <v-row
                      class="ma-0"
                      @click="clickexpander(1)"
                    >
                      <v-col cols="1">
                        <span>
                          <v-icon color="black">{{ innerexpand == 1 ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                        </span>
                      </v-col>
                      <v-col cols="1">
                        Notes({{ item.notes.length }})
                      </v-col>
                    </v-row>

                    <v-expand-transition>
                      <v-card
                        block
                        v-show="innerexpand == 1"
                        class="mx-auto pa-3 overflow-y-auto"
                        max-height="600"
                      >
                        <v-row justify="start" v-show="!newnote">
                          <v-col cols="3">
                            <v-btn @click="newnote = true" text x-small>create new<v-icon color="black">mdi-plus</v-icon></v-btn>
                          </v-col>
                        </v-row>
                        <div v-show="newnote">
                          <v-row class="my-0" >
                            <v-textarea
                              class="px-6 my-0 py-0"
                              auto-grow
                              outlined
                              rows="4"
                              row-height="30"
                              v-model="newnotetext"
                            ></v-textarea>
                          </v-row>
                          <v-row v-show="noteerror" justify="center">
                            <span style="color: red;">you must be an admin to save notes</span>
                          </v-row>
                          <v-row class="my-0" justify="center">
                            <v-btn @click="savenote(item)" x-small text>save</v-btn>
                            <v-btn @click="resetnote" x-small text>cancel</v-btn>
                          </v-row>
                        </div>
                        <div v-if="item.notes.length > 0" >
                          <v-card v-for="n in item.notes" :key="n.id" class="note my-2">
                            <v-card-text align="left" >
                              {{ n.note }}
                            </v-card-text>
                            <v-row class="ma-0 py-0 px-2 notedate" justify="start" align="end">
                                {{ n.datecreated }}
                            </v-row>
                          </v-card>
                        </div>
                        <v-row v-else justify="center">
                          there are no notes for this wager.
                        </v-row>
                      </v-card>
                    </v-expand-transition>
                  </v-col>
                </v-row>
              </td>
            </tr>
            

          </template>
        </v-data-table>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>
<script>
import UserService from '@/services/UserService'
import WagerService from '@/services/WagerService'
import BettorInfo from '@/components/wagers/BettorInfo'
import WagerPayments from '@/components/wagers/Payments'
import ChatLog from '@/components/wagers/ChatLog'

export default {
  name: 'Wagers',
  components: {
    BettorInfo,
    WagerPayments,
    ChatLog
  },
  data: function () {
    return {
      tab: null,
      expanded: [],
      tabs: [
        { name: 'Open', wagers: [], expanded: [], page: 1 },
        { name: 'In Progress', wagers: [], expanded: [], page: 1 },
        { name: 'Awaiting Payment', wagers: [], expanded: [], page: 1 },
        { name: 'Awaiting Confirmation', wagers: [], expanded: [], page: 1 },
        { name: 'Disputed', wagers: [], expanded: [], page: 1 },
        { name: 'Complete', wagers: [], expanded: [], page: 1 },
        { name: 'Cancelled', wager: [], expanded: [], page: 1 },
        { name: 'Deleted', wager: [], expanded: [], page: 1 },
        { name: 'Not Taken', wager: [], expanded: [], page: 1 }
      ],
      form: {
        startdate: null,
        enddate: null,
        searchtext: null
      },
      datepickers: {
        startdate: {
          open: false
        },
        enddate: {
          open: false
        }
      },
      wagers: [],
      headers: [
        { text: 'Status', value: 'betstatus', align: 'center' },
        { text: 'Game Time', value: 'gametime', align: 'center' },
        { text: 'Away', value: 'away', align: 'center' },
        { text: 'Home', value: 'home', align: 'center' },
        { text: 'Wager ID', value: 'wager_id', align: 'center' },
        { text: 'Offerer Amount', value: 'offeredbyamount', align: 'right' },
        { text: 'Offer Team', value: 'teamofferer', align: 'center' },
        { text: 'Offered By', value: 'offerer', align: 'center' },
        { text: 'Taker Amount', value: 'takenbyamount', align: 'right' },
        { text: 'Taken Team', value: 'teamtaker', align: 'center' },
        { text: 'Taken By', value: 'taker', align: 'center' },
        { text: '', value: 'data-table-expand' }
      ],
      refreshDetail: 1,
      refreshTabs: -1,
      innerexpand: -1,
      newnote: null,
      newnotetext:'',
      noteerror: null
    }
  },
  mounted: function () {
    this.init()
  },
  methods: {
    logout () {
      return UserService.logout()
    },

    async init () {
      if (!this.form.startdate) {
        const newdate = new Date((new Date()).valueOf() - 1000 * 60 * 60 * 24 * 2);
        this.form.startdate = new Date(newdate.getTime() - (newdate.getTimezoneOffset() * 60000)).toISOString().split("T")[0];
      }
      this.search()
    },

    async search () {
      this.$updateloading(1)
      await WagerService.search(this.form).then(
        (response) => {
          this.wagers = response.data.results
          // reset tabs
          this.tabs.forEach((t) => {
            t.wagers = []
          })
          // sort wagers into status buckets
          this.wagers.forEach((w) => {
            w.details = { id: 0 }

            switch (w.betstatus) {
              case 'UPCOMING':
              case 'OPEN':
                this.tabs[0].wagers.push(w)
                break

              case 'IN PROGRESS':
                this.tabs[1].wagers.push(w)
                break

              case 'AWAITING PAYMENT':
                this.tabs[2].wagers.push(w)
                break

              case 'AWAITING CONFIRMATION':
                this.tabs[3].wagers.push(w)
                break

              case 'DISPUTED':
                this.tabs[4].wagers.push(w)
                break

              case 'COMPLETE':
                this.tabs[5].wagers.push(w)
                break
              case 'CANCELLED':
                this.tabs[6].wagers.push(w)
                break
              case 'DELETED':
                this.tabs[7].wagers.push(w)
                break
              case 'NOT TAKEN':
                this.tabs[8].wagers.push(w)
                break

            }
          })
          this.refreshTabs--
          this.$updateloading(-1)
        },
        (error) => {
          alert('Failed to load wagers!')
          console.dir(error)
          this.$updateloading(-1)
        }
      )
    },

    async loadWagerDetail (wager) {
      console.log('loadWagerDetail called')
      if (!wager.details || !wager.details.id) {
        await WagerService.get(wager.item.wager_id).then(
          (response) => {
            wager.details = response.data
            wager.loading = false
            // console.dir(response.data)
          },
          (error) => {
            wager.loading = false
            alert('Failed to load wager detail!')
            console.dir(error)
          }
        )
        this.refreshDetail++
      }
    },

    showSpread (line) {
      if (line > 0) {
        return '+' + line
      } else if (line < 0) {
        return line
      } else {
        return 'PK'
      }
    },

    clickexpander: function (id) {
      if (this.innerexpand === id) {
        this.innerexpand = -1
      } else {
        this.innerexpand = id
      }
    },

    savenote: function (item) {
      const data = {
        note: this.newnotetext,
        wager_id: item.wager_id
      }
      if (UserService.isAdmin()) {
        WagerService.savenote(data)
        .then( () => {
          this.resetnote()
          this.init()
        })
        .catch( error => {
          console.log(error)
        })
      } else {
        this.noteerror = true;
        setTimeout(() => {
          this.noteerror = false;
        }, 2000)
      }
    },

    resetnote: function () {
      this.newnotetext = ''
      this.newnote = null
    }
  }
}
</script>
<style scoped>
.winner {
  background-color: rgb(0, 255, 0, 0.2);
}
.loser {
  background-color: rgb(255, 0, 0, 0.2);
}
.notedate {
  font-size: 11px;
}
</style>
