<template>
  <div>
    <v-dialog v-model="suspendDialog" max-width="290">
      <Suspend :user="formattedUserObj" @cancel="suspendDialog = false" @update="suspendedUpdate()"></Suspend>
    </v-dialog>
    <div v-if="type === 'offer'">
      <h2 v-if="wager.bettype_id === 20">{{ (wager.offeredbyteam_id === wager.hometeam_id) ? 'OVER' : 'UNDER' }} {{ wager.lineamount }}</h2>
      <h2 v-else>
        {{ (wager.offeredbyteam_id === wager.hometeam_id) ? wager.hometeam : wager.awayteam }}
        ({{ showSpread((wager.offeredbyteam_id === wager.hometeam_id) ? wager.lineamount : wager.lineamount * -1) }})
      </h2>
      Offered By: {{ wager.offerer }} <v-btn small class="ml-3" @click="suspendDialog = true">Suspend</v-btn><br>
      {{ wager.offerernickname }} ({{ wager.offeredby_id }})<br>
      <a :href="'mailto:' + wager.offereremail">{{ wager.offereremail }}</a><br>
      <a :href="'tel:' + wager.offererphone">{{ wager.offererphone }}</a><br>
      <a
        :href="'//venmo.com/' + wager.offerpaymentmethod[0].value"
        target="_blank"
      >@{{ wager.offerpaymentmethod[0].value }}</a><br>
      Rating: {{ wager.offer_rating }}<br>
      Bet Limit: ${{ wager.offererlimit }}<br>
      <span v-if="wager.winner_id">
        <span v-if="wager.winner_id === wager.offeredby_id">
          <span v-if="wager.paymentreceived">
            <h3>Payment Received</h3>
            <h4>{{ wager.paymentreceived }}</h4>
          </span>
          <span v-else>
            <WagerPayments
              v-if="wager.paymentsent"
              :wager="wager"
              action="Unpaid"
              @update="$emit('update')"
            ></WagerPayments>
          </span>
        </span>
        <span v-if="wager.loser_id === wager.offeredby_id">
          <span v-if="wager.paymentsent">
            <h3>Payment Sent</h3>
            <h4>{{ wager.paymentsent }}</h4>
          </span>
          <span v-else>
            <WagerPayments
              :wager="wager"
              action="Unpaid"
              @update="$emit('update')"
            ></WagerPayments>
          </span>
        </span>
      </span>
    </div>

    <div v-if="type === 'taker'">
      <h2 v-if="wager.bettype_id === 20">{{ (wager.takenbyteam_id === wager.hometeam_id) ? 'OVER' : 'UNDER' }} {{ wager.lineamount }}</h2>
      <h2 v-else>
        {{ (wager.takenbyteam_id === wager.hometeam_id) ? wager.hometeam : wager.awayteam }}
        ({{ showSpread((wager.takenbyteam_id === wager.hometeam_id) ? wager.lineamount : wager.lineamount * -1) }})
      </h2>
      Taken By: {{ wager.taker }} <v-btn small class="ml-3" @click="suspendDialog = true">Suspend</v-btn><br>
      {{ wager.takernickname }} ({{ wager.takenby_id }})<br>
      <a :href="'mailto:' + wager.takeremail">{{ wager.takeremail }}</a><br>
      <a :href="'tel:' + wager.takerphone">{{ wager.takerphone }}</a><br>
      <a
        :href="'//venmo.com/' + wager.takerpaymentmethod[0].value"
        target="_blank"
      >@{{ wager.takerpaymentmethod[0].value }}</a><br>
      Rating: {{ wager.taker_rating }}<br>
      Bet Limit: ${{ wager.takerlimit }}<br>
      <span v-if="wager.winner_id">
        <span v-if="wager.winner_id === wager.takenby_id">
          <span v-if="wager.paymentreceived">
            <h3>Payment Received</h3>
            <h4>{{ wager.paymentreceived }}</h4>
          </span>
          <span v-else>
            <WagerPayments
              v-if="wager.paymentsent"
              :wager="wager"
              action="Unconfirmed"
              @update="$emit('update')"
            ></WagerPayments>
          </span>
        </span>
        <span v-if="wager.loser_id === wager.takenby_id">
          <span v-if="wager.paymentsent">
            <h3>Payment Sent</h3>
            <h4>{{ wager.paymentsent }}</h4>
          </span>
          <span v-else>
            <WagerPayments
              :wager="wager"
              action="Unpaid"
              @update="$emit('update')"
            ></WagerPayments>
          </span>
        </span>
      </span>
    </div>
  </div>
</template>

<script>
import WagerPayments from '@/components/wagers/Payments'
import Suspend from '@/components/widget/Suspend'

export default {
  name: 'BettorInfo',
  data: () => {
    return {
      suspendDialog: false
    }
  },
  components: {
    WagerPayments,
    Suspend
  },
  props: {
    wager: null,
    type: null
  },
  methods: {
    showSpread (line) {
      if (line > 0) {
        return '+' + line
      } else if (line < 0) {
        return line
      } else {
        return 'PK'
      }
    },
    suspendedUpdate: function () {
      this.suspendDialog = false
      this.$emit('update')
    }
  },
  computed: {
    formattedUserObj: function () {
      var user = null
      if (this.type == 'taker') {
        user = {
          id: this.wager.takenby_id,
          name: this.wager.taker,
          username: this.wager.takernickname
        }
      } else {
        user = {
          id: this.wager.offeredby_id,
          name: this.wager.offerer,
          username: this.wager.offerernickname
        }
      }
      user.wager_id = this.wager.wager_id
      return user
    }
  }
}
</script>