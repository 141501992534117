var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("v-data-table", {
        staticClass: "elevation-1 mt-0",
        attrs: {
          dense: "",
          headers: _vm.headers,
          items: _vm.games,
          "footer-props": { itemsPerPageOptions: [-1, 15, 25, 50] },
          "header-props": { sortIcon: null },
          "items-per-page": 25,
          "item-key": "id"
        },
        scopedSlots: _vm._u([
          {
            key: "top",
            fn: function() {
              return [
                _c(
                  "v-toolbar",
                  { attrs: { flat: "" } },
                  [
                    _c("v-toolbar-title", [_vm._v("Games Without Scores")]),
                    _c("v-spacer")
                  ],
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "item",
            fn: function(ref) {
              var item = ref.item
              return [
                _c("tr", [
                  _c("td", [_vm._v(_vm._s(item.id))]),
                  _c("td", { staticClass: "text-left" }, [
                    _vm._v(_vm._s(item.starttime))
                  ]),
                  _c("td", [_vm._v(_vm._s(item.league))]),
                  _c("td", { staticClass: "text-left" }, [
                    _vm._v(_vm._s(item.description))
                  ]),
                  _c(
                    "td",
                    [
                      _c("v-text-field", {
                        attrs: {
                          inputmode: "numeric",
                          placeholder: item.awayteam + " score",
                          type: "number",
                          "hide-details": "",
                          "single-line": "",
                          dense: ""
                        },
                        model: {
                          value: item.awayteamscore,
                          callback: function($$v) {
                            _vm.$set(item, "awayteamscore", _vm._n($$v))
                          },
                          expression: "item.awayteamscore"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "td",
                    [
                      _c("v-text-field", {
                        staticClass: "score",
                        attrs: {
                          inputmode: "numeric",
                          placeholder: item.hometeam + " score",
                          type: "number",
                          "hide-details": "",
                          "single-line": "",
                          dense: ""
                        },
                        model: {
                          value: item.hometeamscore,
                          callback: function($$v) {
                            _vm.$set(item, "hometeamscore", _vm._n($$v))
                          },
                          expression: "item.hometeamscore"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "td",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "secondary",
                            small: "",
                            disabled: _vm.scoreIsInvalid(item)
                          },
                          on: {
                            click: function($event) {
                              return _vm.finalizeGame(item)
                            }
                          }
                        },
                        [_vm._v("Finalize ")]
                      )
                    ],
                    1
                  )
                ])
              ]
            }
          }
        ])
      }),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c(
                "v-btn",
                {
                  staticClass: "ma-2",
                  attrs: { color: "primary", dark: "" },
                  on: { click: _vm.finalizeAllGames }
                },
                [_vm._v(" Finalize All Games With Scores ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }