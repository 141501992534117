<template>
  <div>
    <v-container
      style="min-height: 200px; overflow-x: hidden;"
      class="overflow-y-auto pa-0 my-0"
    >
      <div>
        <v-row
          v-for="m in messages"
          :key="m.id"
          :justify="(m.user_id == m.offeredby_id) ? 'end' : 'start'"
          class="mt-0 mb-1"
        >
          <v-col
            class="py-0"
            :class="(m.user_id == m.offeredby_id) ? 'text-right' : 'text-left'"
          >
            <div class="timestamp">{{ m.created }}</div>
            <div
              class="message"
              :class="(m.user_id == m.offeredby_id) ? 'message-from' : 'message-to'"
            >
              <div>{{ m.message }}</div>
            </div>
            <div class="timestamp">{{ m.sendername }}</div>
          </v-col>
        </v-row>
        <v-row v-if="messages.length == 0">
          <v-col>
            No messages
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>
<script>
import ChatService from '@/services/ChatService'

export default {
  components: {},
  props: {
    wager_id: {
      type: Number,
      default: 0
    }
  },
  mounted: function () {
    ChatService.getChatHistory(this.wager_id).then(
      (response) => {
        this.messages = response.data
      },
      (err) => {
        console.log(err)
      }
    )
  },
  data: () => {
    return {
      messages: []
    }
  }
}
</script>
<style scoped>
.timestamp {
  font-size: 70%;
  color: #aaa;
}
.message {
  word-wrap: break-word;
  overflow-wrap: break-word;
  display: inline-block;
  max-width: 80%;
}
.message-from {
  background: #c12626;
  color: white;
  padding: 4px;
  border-radius: 5px 5px 0 5px;
  margin-left: 15px;
}
.message-to {
  background: #272727;
  color: white;
  padding: 4px;
  border-radius: 5px 5px 5px 0;
  margin-right: 15px;
  min-width: 30%;
  max-width: 80%;
  min-height: 25px;
}

/*** iPhone and iOS Form Input Zoom Fixes ***/
/* Fix Input Zoom on devices older than iPhone 5: */
.v-input {
  font-size: 16px !important;
}
</style>
