var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "290" },
          model: {
            value: _vm.suspendDialog,
            callback: function($$v) {
              _vm.suspendDialog = $$v
            },
            expression: "suspendDialog"
          }
        },
        [
          _c("Suspend", {
            attrs: { user: _vm.formattedUserObj },
            on: {
              cancel: function($event) {
                _vm.suspendDialog = false
              },
              update: function($event) {
                return _vm.suspendedUpdate()
              }
            }
          })
        ],
        1
      ),
      _vm.type === "offer"
        ? _c(
            "div",
            [
              _vm.wager.bettype_id === 20
                ? _c("h2", [
                    _vm._v(
                      _vm._s(
                        _vm.wager.offeredbyteam_id === _vm.wager.hometeam_id
                          ? "OVER"
                          : "UNDER"
                      ) +
                        " " +
                        _vm._s(_vm.wager.lineamount)
                    )
                  ])
                : _c("h2", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.wager.offeredbyteam_id === _vm.wager.hometeam_id
                            ? _vm.wager.hometeam
                            : _vm.wager.awayteam
                        ) +
                        " (" +
                        _vm._s(
                          _vm.showSpread(
                            _vm.wager.offeredbyteam_id === _vm.wager.hometeam_id
                              ? _vm.wager.lineamount
                              : _vm.wager.lineamount * -1
                          )
                        ) +
                        ") "
                    )
                  ]),
              _vm._v(" Offered By: " + _vm._s(_vm.wager.offerer) + " "),
              _c(
                "v-btn",
                {
                  staticClass: "ml-3",
                  attrs: { small: "" },
                  on: {
                    click: function($event) {
                      _vm.suspendDialog = true
                    }
                  }
                },
                [_vm._v("Suspend")]
              ),
              _c("br"),
              _vm._v(
                " " +
                  _vm._s(_vm.wager.offerernickname) +
                  " (" +
                  _vm._s(_vm.wager.offeredby_id) +
                  ")"
              ),
              _c("br"),
              _c("a", { attrs: { href: "mailto:" + _vm.wager.offereremail } }, [
                _vm._v(_vm._s(_vm.wager.offereremail))
              ]),
              _c("br"),
              _c("a", { attrs: { href: "tel:" + _vm.wager.offererphone } }, [
                _vm._v(_vm._s(_vm.wager.offererphone))
              ]),
              _c("br"),
              _c(
                "a",
                {
                  attrs: {
                    href:
                      "//venmo.com/" + _vm.wager.offerpaymentmethod[0].value,
                    target: "_blank"
                  }
                },
                [_vm._v("@" + _vm._s(_vm.wager.offerpaymentmethod[0].value))]
              ),
              _c("br"),
              _vm._v(" Rating: " + _vm._s(_vm.wager.offer_rating)),
              _c("br"),
              _vm._v(" Bet Limit: $" + _vm._s(_vm.wager.offererlimit)),
              _c("br"),
              _vm.wager.winner_id
                ? _c("span", [
                    _vm.wager.winner_id === _vm.wager.offeredby_id
                      ? _c("span", [
                          _vm.wager.paymentreceived
                            ? _c("span", [
                                _c("h3", [_vm._v("Payment Received")]),
                                _c("h4", [
                                  _vm._v(_vm._s(_vm.wager.paymentreceived))
                                ])
                              ])
                            : _c(
                                "span",
                                [
                                  _vm.wager.paymentsent
                                    ? _c("WagerPayments", {
                                        attrs: {
                                          wager: _vm.wager,
                                          action: "Unpaid"
                                        },
                                        on: {
                                          update: function($event) {
                                            return _vm.$emit("update")
                                          }
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                        ])
                      : _vm._e(),
                    _vm.wager.loser_id === _vm.wager.offeredby_id
                      ? _c("span", [
                          _vm.wager.paymentsent
                            ? _c("span", [
                                _c("h3", [_vm._v("Payment Sent")]),
                                _c("h4", [
                                  _vm._v(_vm._s(_vm.wager.paymentsent))
                                ])
                              ])
                            : _c(
                                "span",
                                [
                                  _c("WagerPayments", {
                                    attrs: {
                                      wager: _vm.wager,
                                      action: "Unpaid"
                                    },
                                    on: {
                                      update: function($event) {
                                        return _vm.$emit("update")
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                        ])
                      : _vm._e()
                  ])
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm.type === "taker"
        ? _c(
            "div",
            [
              _vm.wager.bettype_id === 20
                ? _c("h2", [
                    _vm._v(
                      _vm._s(
                        _vm.wager.takenbyteam_id === _vm.wager.hometeam_id
                          ? "OVER"
                          : "UNDER"
                      ) +
                        " " +
                        _vm._s(_vm.wager.lineamount)
                    )
                  ])
                : _c("h2", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.wager.takenbyteam_id === _vm.wager.hometeam_id
                            ? _vm.wager.hometeam
                            : _vm.wager.awayteam
                        ) +
                        " (" +
                        _vm._s(
                          _vm.showSpread(
                            _vm.wager.takenbyteam_id === _vm.wager.hometeam_id
                              ? _vm.wager.lineamount
                              : _vm.wager.lineamount * -1
                          )
                        ) +
                        ") "
                    )
                  ]),
              _vm._v(" Taken By: " + _vm._s(_vm.wager.taker) + " "),
              _c(
                "v-btn",
                {
                  staticClass: "ml-3",
                  attrs: { small: "" },
                  on: {
                    click: function($event) {
                      _vm.suspendDialog = true
                    }
                  }
                },
                [_vm._v("Suspend")]
              ),
              _c("br"),
              _vm._v(
                " " +
                  _vm._s(_vm.wager.takernickname) +
                  " (" +
                  _vm._s(_vm.wager.takenby_id) +
                  ")"
              ),
              _c("br"),
              _c("a", { attrs: { href: "mailto:" + _vm.wager.takeremail } }, [
                _vm._v(_vm._s(_vm.wager.takeremail))
              ]),
              _c("br"),
              _c("a", { attrs: { href: "tel:" + _vm.wager.takerphone } }, [
                _vm._v(_vm._s(_vm.wager.takerphone))
              ]),
              _c("br"),
              _c(
                "a",
                {
                  attrs: {
                    href:
                      "//venmo.com/" + _vm.wager.takerpaymentmethod[0].value,
                    target: "_blank"
                  }
                },
                [_vm._v("@" + _vm._s(_vm.wager.takerpaymentmethod[0].value))]
              ),
              _c("br"),
              _vm._v(" Rating: " + _vm._s(_vm.wager.taker_rating)),
              _c("br"),
              _vm._v(" Bet Limit: $" + _vm._s(_vm.wager.takerlimit)),
              _c("br"),
              _vm.wager.winner_id
                ? _c("span", [
                    _vm.wager.winner_id === _vm.wager.takenby_id
                      ? _c("span", [
                          _vm.wager.paymentreceived
                            ? _c("span", [
                                _c("h3", [_vm._v("Payment Received")]),
                                _c("h4", [
                                  _vm._v(_vm._s(_vm.wager.paymentreceived))
                                ])
                              ])
                            : _c(
                                "span",
                                [
                                  _vm.wager.paymentsent
                                    ? _c("WagerPayments", {
                                        attrs: {
                                          wager: _vm.wager,
                                          action: "Unconfirmed"
                                        },
                                        on: {
                                          update: function($event) {
                                            return _vm.$emit("update")
                                          }
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                        ])
                      : _vm._e(),
                    _vm.wager.loser_id === _vm.wager.takenby_id
                      ? _c("span", [
                          _vm.wager.paymentsent
                            ? _c("span", [
                                _c("h3", [_vm._v("Payment Sent")]),
                                _c("h4", [
                                  _vm._v(_vm._s(_vm.wager.paymentsent))
                                ])
                              ])
                            : _c(
                                "span",
                                [
                                  _c("WagerPayments", {
                                    attrs: {
                                      wager: _vm.wager,
                                      action: "Unpaid"
                                    },
                                    on: {
                                      update: function($event) {
                                        return _vm.$emit("update")
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                        ])
                      : _vm._e()
                  ])
                : _vm._e()
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }