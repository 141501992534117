var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("v-data-table", {
        staticClass: "elevation-1 mt-0",
        attrs: {
          dense: "",
          headers: _vm.headers,
          items: _vm.results,
          "footer-props": { itemsPerPageOptions: [-1, 15, 25, 50] },
          "header-props": { sortIcon: null },
          "items-per-page": 25,
          "item-key": "id"
        },
        scopedSlots: _vm._u([
          {
            key: "top",
            fn: function() {
              return [
                _c(
                  "v-toolbar",
                  { attrs: { flat: "" } },
                  [
                    _c("v-toolbar-title", [_vm._v("Progress Summary")]),
                    _c("v-spacer")
                  ],
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "item",
            fn: function(ref) {
              var item = ref.item
              return [
                _c("tr", [
                  _c("td", [_vm._v(_vm._s(item.week))]),
                  _c("td", [_vm._v(_vm._s(item.ending))]),
                  _c("td", { staticClass: "text-right" }, [
                    _vm._v(_vm._s(_vm._f("commanum")(item.invites)))
                  ]),
                  _c("td", { staticClass: "text-right" }, [
                    _vm._v(_vm._s(_vm._f("commanum")(item.SWconv)))
                  ]),
                  _c("td", { staticClass: "text-right" }, [
                    _vm._v(_vm._s(item.SWpct))
                  ]),
                  _c("td", { staticClass: "text-right" }, [
                    _vm._v(_vm._s(_vm._f("commanum")(item.conv)))
                  ]),
                  _c("td", { staticClass: "text-right" }, [
                    _vm._v(_vm._s(item.CNVpct))
                  ]),
                  _c("td", { staticClass: "text-right" }, [
                    _vm._v(_vm._s(_vm._f("commanum")(item.users)))
                  ]),
                  _c("td", { staticClass: "text-right" }, [
                    _vm._v(_vm._s(_vm._f("commanum")(item.organicusers)))
                  ]),
                  _c("td", { staticClass: "text-right" }, [
                    _vm._v(_vm._s(_vm._f("commanum")(item.affiliateusers)))
                  ]),
                  _c("td", { staticClass: "text-right" }, [
                    _vm._v(_vm._s(item.UtoIpct))
                  ]),
                  _c("td", { staticClass: "text-right" }, [
                    _vm._v(_vm._s(_vm._f("commanum")(item.numOffers)))
                  ]),
                  _c("td", { staticClass: "text-right" }, [
                    _vm._v("$" + _vm._s(_vm._f("commanum")(item.offered$)))
                  ]),
                  _c("td", { staticClass: "text-right" }, [
                    _vm._v(_vm._s(_vm._f("commanum")(item.numWagers)))
                  ]),
                  _c("td", { staticClass: "text-right" }, [
                    _vm._v(_vm._s(item.pctTaken))
                  ]),
                  _c("td", { staticClass: "text-right" }, [
                    _vm._v("$" + _vm._s(_vm._f("commanum")(item.wagered$)))
                  ]),
                  _c("td", { staticClass: "text-right" }, [
                    _vm._v("$" + _vm._s(_vm._f("commanum")(item.avgbet)))
                  ]),
                  _c("td", { staticClass: "text-right" }, [
                    _vm._v(_vm._s(_vm._f("commanum")(item.numWagersGroup)))
                  ]),
                  _c("td", { staticClass: "text-right" }, [
                    _vm._v(_vm._s(_vm._f("commanum")(item.wageredGroup)))
                  ])
                ])
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }