import axios from 'axios'
import UserService from '@/services/UserService'

const ProgressService = {

  async get () {
    await UserService.authHeader()
    return axios({
      url: UserService.baseUrl() + 'wager/admin/progresssummary',
      method: 'GET'
    })
  },

}

export default ProgressService
