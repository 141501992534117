<template>
  <v-container>
    <v-data-table
      dense
      :headers="headers"
      :items="games"
      :footer-props="{ itemsPerPageOptions:[ -1, 15, 25, 50 ] }"
      :header-props="{ sortIcon: null }"
      :items-per-page="25"
      item-key="id"
      class="elevation-1 mt-0"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Games Without Scores</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
      </template>

      <template v-slot:item="{ item }">
        <tr>
          <td>{{ item.id }}</td>
          <td class="text-left">{{ item.starttime }}</td>
          <td>{{ item.league }}</td>
          <td class="text-left">{{ item.description }}</td>
          <td>
            <v-text-field
              inputmode="numeric"
              :placeholder="item.awayteam + ' score'"
              v-model.number="item.awayteamscore"
              type="number"
              hide-details
              single-line
              dense
            ></v-text-field>
          </td>
          <td>
            <v-text-field
              class="score"
              inputmode="numeric"
              :placeholder="item.hometeam + ' score'"
              v-model.number="item.hometeamscore"
              type="number"
              hide-details
              single-line
              dense
            ></v-text-field>
          </td>
          <td>
            <v-btn
              color="secondary"
              small
              @click="finalizeGame(item)"
              :disabled="scoreIsInvalid(item)"
            >Finalize
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>

    <v-row>
      <v-col>
        <v-btn
          color="primary"
          dark
          class="ma-2"
          @click="finalizeAllGames"
        >
          Finalize All Games With Scores
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import GameService from '@/services/GameService'

export default {
  name: 'Games',
  data: function () {
    return {
      games: [],
      headers: [
        { text: 'ID', value: 'id', align: 'center' },
        { text: 'Start', value: 'starttime', align: 'left' },
        { text: 'League', value: 'league', align: 'center' },
        { text: 'Game', value: 'decription', align: 'left' },
        { text: 'Away Team Score', value: 'awayteam', align: 'center' },
        { text: 'Home Team Score', value: 'hometeam', align: 'center' },
        { text: '' }
      ]
    }
  },
  mounted: function () {
    this.init()
  },
  methods: {
    async init () {
      this.$updateloading(1)
      await GameService.search({ needingscores: true }).then(
        (response) => {
          this.games = response.data.results
          this.$updateloading(-1)
        },
        (error) => {
          alert('Failed to load games!')
          console.dir(error)
          this.$updateloading(-1)
        }
      )
    },

    async finalizeGame (item) {
      if (this.scoreIsInvalid(item)) {
        alert('The score is invalid!')
        return false
      }
      var conf = confirm('Are you sure you want to finalize this game?')
      if (!conf) {
        return false
      }
      var data = {
        id: item.id,
        awayteamscore: item.awayteamscore,
        hometeamscore: item.hometeamscore
      }
      this.$updateloading(1)
      await GameService.finalize(data).then(
        (response) => {
          console.dir(response)
          this.init()
          this.$updateloading(-1)
        },
        (error) => {
          console.dir(error)
          alert('Failed to finalize game! ' + error.response.data.error)
          this.$updateloading(-1)
        }
      )
    },

    async finalizeAllGames () {
      var games = []
      this.games.forEach((g) => {
        if (!this.scoreIsInvalid(g)) {
          games.push(g)
        }
      })
      if (games.length > 0) {
        this.$updateloading(1)
        await GameService.finalizeAllGames(games).then(
          (response) => {
            console.dir(response)
            this.init()
            this.$updateloading(-1)
          },
          (error) => {
            console.dir(error)
            alert('Failed to finalize game! ' + error.response.data.error)
            this.$updateloading(-1)
          }
        )
      } else {
        alert('No games to finalize!')
        return false
      }
    },

    scoreIsInvalid (item) {
      return item.awayteamscore === null || item.awayteamscore === '' || item.hometeamscore === null || item.hometeamscore === ''
    }

  }
}
</script>
