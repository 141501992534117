var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { attrs: { justify: "center" } },
    [
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "800px" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                var attrs = ref.attrs
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        { attrs: { color: "primary", dark: "", small: "" } },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [_vm._v(" " + _vm._s(_vm.action) + " ")]
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _c("span", { staticClass: "headline" }, [
                  _vm._v("Wager #" + _vm._s(_vm.wager.id))
                ])
              ]),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "6" } },
                            [
                              _c("h1", [_vm._v("Winner")]),
                              _vm.wager.winner_id === _vm.wager.offeredby_id
                                ? _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.wager.offerernickname) +
                                        " (" +
                                        _vm._s(_vm.wager.offeredby_id) +
                                        ")"
                                    ),
                                    _c("br"),
                                    _vm._v(" " + _vm._s(_vm.wager.offerer)),
                                    _c("br"),
                                    _vm._v(
                                      " $" +
                                        _vm._s(_vm.wager.offeredbyamount) +
                                        " "
                                    )
                                  ])
                                : _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.wager.takernickname) +
                                        " (" +
                                        _vm._s(_vm.wager.takenby_id) +
                                        ")"
                                    ),
                                    _c("br"),
                                    _vm._v(" " + _vm._s(_vm.wager.taker)),
                                    _c("br"),
                                    _vm._v(
                                      " $" +
                                        _vm._s(_vm.wager.takenbyamount) +
                                        " "
                                    )
                                  ]),
                              _c("br"),
                              _c("v-text-field", {
                                attrs: {
                                  label: "Payment Confirmed",
                                  placeholder: "YYYY-MM-DD HH:MM:SS",
                                  "hide-details": "",
                                  clearable: ""
                                },
                                on: {
                                  focus: function($event) {
                                    return _vm.setDateIfEmptyOnFirstTouch(
                                      "paymentreceived"
                                    )
                                  }
                                },
                                model: {
                                  value: _vm.paymentreceived,
                                  callback: function($$v) {
                                    _vm.paymentreceived = $$v
                                  },
                                  expression: "paymentreceived"
                                }
                              }),
                              _c("br"),
                              _c(
                                "v-btn",
                                {
                                  attrs: { small: "", color: "primary" },
                                  on: { click: _vm.updatePaymentConfirmation }
                                },
                                [_vm._v("Update Confirmation")]
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "6" } },
                            [
                              _c("h1", [_vm._v("Loser")]),
                              _vm.wager.loser_id === _vm.wager.offeredby_id
                                ? _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.wager.offerernickname) +
                                        " (" +
                                        _vm._s(_vm.wager.offeredby_id) +
                                        ")"
                                    ),
                                    _c("br"),
                                    _vm._v(" " + _vm._s(_vm.wager.offerer)),
                                    _c("br"),
                                    _vm._v(
                                      " $" +
                                        _vm._s(_vm.wager.offeredbyamount) +
                                        " "
                                    )
                                  ])
                                : _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.wager.takernickname) +
                                        " (" +
                                        _vm._s(_vm.wager.takenby_id) +
                                        ")"
                                    ),
                                    _c("br"),
                                    _vm._v(" " + _vm._s(_vm.wager.taker)),
                                    _c("br"),
                                    _vm._v(
                                      " $" +
                                        _vm._s(_vm.wager.takenbyamount) +
                                        " "
                                    )
                                  ]),
                              _c("br"),
                              _c("v-text-field", {
                                attrs: {
                                  label: "Payment Sent",
                                  placeholder: "YYYY-MM-DD HH:MM:SS",
                                  "hide-details": "",
                                  clearable: ""
                                },
                                on: {
                                  focus: function($event) {
                                    return _vm.setDateIfEmptyOnFirstTouch(
                                      "paymentsent"
                                    )
                                  }
                                },
                                model: {
                                  value: _vm.paymentsent,
                                  callback: function($$v) {
                                    _vm.paymentsent = $$v
                                  },
                                  expression: "paymentsent"
                                }
                              }),
                              _c("br"),
                              _c(
                                "v-btn",
                                {
                                  attrs: { small: "", color: "primary" },
                                  on: { click: _vm.updatePaymentSent }
                                },
                                [_vm._v("Update Payment Sent")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "blue darken-1", text: "" },
                      on: { click: _vm.close }
                    },
                    [_vm._v(" Close ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }