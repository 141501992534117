import axios from 'axios'
import UserService from '@/services/UserService'

const WagerService = {

  async search (filters, perpage, page, sort) {
    await UserService.authHeader()
    var data = {
      filters: filters,
      perpage: perpage,
      page: page,
      sort: sort
    }
    return axios({
      url: UserService.baseUrl() + 'wager/admin/list',
      data: JSON.stringify(data),
      method: 'POST'
    })
  },

  async get (id) {
    await UserService.authHeader()
    return axios({
      url: UserService.baseUrl() + 'wager/admin/get/' + id,
      method: 'GET'
    })
  },

  async updatePaymentSent (id, date) {
    await UserService.authHeader()
    return axios({
      url: UserService.baseUrl() + 'wager/admin/payment/sent/',
      data: JSON.stringify({ wager_id: id, paymentsent: date }),
      method: 'POST'
    })
  },

  async updatePaymentConfirmation (id, date) {
    await UserService.authHeader()
    return axios({
      url: UserService.baseUrl() + 'wager/admin/payment/confirm/',
      data: JSON.stringify({ wager_id: id, paymentreceived: date }),
      method: 'POST'
    })
  },

  async savenote (note) {
    await UserService.authHeader()
    return axios({
      url: UserService.baseUrl() + 'wager/admin/customerservice/savenote/',
      data: JSON.stringify(note),
      method: 'POST'
    })
  },

  async getnotes (id) {
    await UserService.authHeader()
    return axios({
      url: UserService.baseUrl() + 'wager/admin/customerservice/get/' + id,
      method: 'GET'
    })
  },





}

export default WagerService
