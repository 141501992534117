var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-card-title",
                    {
                      on: {
                        click: function($event) {
                          _vm.filterarrow = !_vm.filterarrow
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "mx-auto" },
                        [
                          _c("span", [_vm._v("Filter")]),
                          _c(
                            "v-icon",
                            {
                              staticClass: "collapse-arrow",
                              class: { "collapse-arrow-down": _vm.filterarrow }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.filterarrow
                                      ? "mdi-chevron-up"
                                      : "mdi-chevron-down"
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ]
                  ),
                  _c("v-expand-transition", [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.filterarrow,
                            expression: "filterarrow"
                          }
                        ]
                      },
                      [
                        _c(
                          "v-row",
                          { staticClass: "ml-4" },
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "6" } },
                              [
                                _c("v-checkbox", {
                                  attrs: { value: "pending", label: "Pending" },
                                  model: {
                                    value: _vm.statusFilters,
                                    callback: function($$v) {
                                      _vm.statusFilters = $$v
                                    },
                                    expression: "statusFilters"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "6" } },
                              [
                                _c("v-checkbox", {
                                  attrs: {
                                    value: "approved",
                                    label: "Approved"
                                  },
                                  model: {
                                    value: _vm.statusFilters,
                                    callback: function($$v) {
                                      _vm.statusFilters = $$v
                                    },
                                    expression: "statusFilters"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "6" } },
                              [
                                _c("v-checkbox", {
                                  attrs: {
                                    value: "rejected",
                                    label: "Rejected"
                                  },
                                  model: {
                                    value: _vm.statusFilters,
                                    callback: function($$v) {
                                      _vm.statusFilters = $$v
                                    },
                                    expression: "statusFilters"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          { staticClass: "ml-4" },
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "3" } },
                              [
                                _c(
                                  "v-menu",
                                  {
                                    attrs: { "close-on-content-click": false },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          var attrs = ref.attrs
                                          return [
                                            _c(
                                              "v-text-field",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    attrs: {
                                                      label: "From Date",
                                                      "prepend-icon":
                                                        "mdi-calendar",
                                                      readonly: "",
                                                      clearable: ""
                                                    },
                                                    on: {
                                                      clear: function($event) {
                                                        return _vm.clearStartDate()
                                                      }
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.startdateDisplay,
                                                      callback: function($$v) {
                                                        _vm.startdateDisplay = $$v
                                                      },
                                                      expression:
                                                        "startdateDisplay"
                                                    }
                                                  },
                                                  "v-text-field",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              )
                                            )
                                          ]
                                        }
                                      }
                                    ]),
                                    model: {
                                      value: _vm.startdatepicker,
                                      callback: function($$v) {
                                        _vm.startdatepicker = $$v
                                      },
                                      expression: "startdatepicker"
                                    }
                                  },
                                  [
                                    _c("v-date-picker", {
                                      attrs: {
                                        max: new Date()
                                          .toISOString()
                                          .substr(0, 10)
                                      },
                                      on: { input: _vm.setStartDate },
                                      model: {
                                        value: _vm.startdate,
                                        callback: function($$v) {
                                          _vm.startdate = $$v
                                        },
                                        expression: "startdate"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "3" } },
                              [
                                _c(
                                  "v-menu",
                                  {
                                    attrs: { "close-on-content-click": false },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          var attrs = ref.attrs
                                          return [
                                            _c(
                                              "v-text-field",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    attrs: {
                                                      label: "To Date",
                                                      "prepend-icon":
                                                        "mdi-calendar",
                                                      readonly: "",
                                                      clearable: ""
                                                    },
                                                    on: {
                                                      clear: function($event) {
                                                        return _vm.clearEndDate()
                                                      }
                                                    },
                                                    model: {
                                                      value: _vm.enddateDisplay,
                                                      callback: function($$v) {
                                                        _vm.enddateDisplay = $$v
                                                      },
                                                      expression:
                                                        "enddateDisplay"
                                                    }
                                                  },
                                                  "v-text-field",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              )
                                            )
                                          ]
                                        }
                                      }
                                    ]),
                                    model: {
                                      value: _vm.enddatepicker,
                                      callback: function($$v) {
                                        _vm.enddatepicker = $$v
                                      },
                                      expression: "enddatepicker"
                                    }
                                  },
                                  [
                                    _c("v-date-picker", {
                                      attrs: {
                                        max: new Date()
                                          .toISOString()
                                          .substr(0, 10)
                                      },
                                      on: { input: _vm.setEndDate },
                                      model: {
                                        value: _vm.enddate,
                                        callback: function($$v) {
                                          _vm.enddate = $$v
                                        },
                                        expression: "enddate"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          { attrs: { justify: "end" } },
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12" } },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "white--text",
                                    attrs: { color: "black" },
                                    on: { click: _vm.applyFilters }
                                  },
                                  [_vm._v("Set Filters")]
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "white--text ml-2",
                                    attrs: { color: "grey" },
                                    on: { click: _vm.clearDates }
                                  },
                                  [_vm._v("Clear Dates")]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "v-tabs",
                {
                  model: {
                    value: _vm.tab,
                    callback: function($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab"
                  }
                },
                _vm._l(_vm.tabs, function(tab) {
                  return _c("v-tab", { key: tab.name }, [
                    _vm._v(_vm._s(tab.name))
                  ])
                }),
                1
              ),
              _c(
                "v-tabs-items",
                {
                  model: {
                    value: _vm.tab,
                    callback: function($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab"
                  }
                },
                _vm._l(_vm.tabs, function(tab) {
                  return _c(
                    "v-tab-item",
                    { key: tab.name },
                    [
                      tab.name !== "Deposits"
                        ? _c("v-data-table", {
                            attrs: {
                              headers: _vm.headers,
                              items: _vm.transactions,
                              "items-per-page": _vm.itemsPerPage,
                              loading: _vm.loading,
                              "server-items-length": _vm.totalItems
                            },
                            on: { pagination: _vm.handlePagination },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "top",
                                  fn: function() {
                                    return [
                                      _c(
                                        "v-toolbar",
                                        { attrs: { flat: "" } },
                                        [
                                          _c("v-toolbar-title", [
                                            _vm._v(_vm._s(tab.name))
                                          ]),
                                          _c("v-spacer"),
                                          _c("v-text-field", {
                                            attrs: {
                                              "append-icon": "mdi-magnify",
                                              label: "Search",
                                              "single-line": "",
                                              "hide-details": ""
                                            },
                                            on: { input: _vm.applyFilters },
                                            model: {
                                              value: _vm.search,
                                              callback: function($$v) {
                                                _vm.search = $$v
                                              },
                                              expression: "search"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  },
                                  proxy: true
                                },
                                {
                                  key: "item.amount",
                                  fn: function(ref) {
                                    var item = ref.item
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.formatAmount(item.amount))
                                        )
                                      ])
                                    ]
                                  }
                                },
                                {
                                  key: "item.actions",
                                  fn: function(ref) {
                                    var item = ref.item
                                    return [
                                      _c(
                                        "v-btn",
                                        {
                                          on: {
                                            click: function($event) {
                                              return _vm.editTransaction(item)
                                            }
                                          }
                                        },
                                        [_vm._v("Edit")]
                                      ),
                                      item.status === "pending"
                                        ? _c(
                                            "v-btn",
                                            {
                                              staticClass: "ml-2 white--text",
                                              attrs: { color: "green" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.updateStatus(
                                                    item,
                                                    "approved"
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v("Approve")]
                                          )
                                        : _vm._e(),
                                      item.status === "pending"
                                        ? _c(
                                            "v-btn",
                                            {
                                              staticClass: "ml-2 white--text",
                                              attrs: { color: "red" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.updateStatus(
                                                    item,
                                                    "rejected"
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v("Reject")]
                                          )
                                        : _vm._e()
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          })
                        : [
                            _c(
                              "v-toolbar",
                              { attrs: { flat: "" } },
                              [
                                _c("v-toolbar-title", [
                                  _vm._v(_vm._s(tab.name))
                                ]),
                                _c("v-spacer"),
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { color: "primary" },
                                    on: {
                                      click: function($event) {
                                        _vm.showAddDepositDialog = true
                                      }
                                    }
                                  },
                                  [_vm._v("Add Deposit")]
                                )
                              ],
                              1
                            ),
                            _c("v-data-table", {
                              attrs: {
                                headers: _vm.headers,
                                items: _vm.transactions,
                                "items-per-page": _vm.itemsPerPage,
                                loading: _vm.loading,
                                "server-items-length": _vm.totalItems
                              },
                              on: { pagination: _vm.handlePagination },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "top",
                                    fn: function() {
                                      return [
                                        _c("v-text-field", {
                                          attrs: {
                                            "append-icon": "mdi-magnify",
                                            label: "Search",
                                            "single-line": "",
                                            "hide-details": ""
                                          },
                                          on: { input: _vm.applyFilters },
                                          model: {
                                            value: _vm.search,
                                            callback: function($$v) {
                                              _vm.search = $$v
                                            },
                                            expression: "search"
                                          }
                                        })
                                      ]
                                    },
                                    proxy: true
                                  },
                                  {
                                    key: "item.amount",
                                    fn: function(ref) {
                                      var item = ref.item
                                      return [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.formatAmount(item.amount)
                                            )
                                          )
                                        ])
                                      ]
                                    }
                                  },
                                  {
                                    key: "item.actions",
                                    fn: function(ref) {
                                      var item = ref.item
                                      return [
                                        _c(
                                          "v-btn",
                                          {
                                            on: {
                                              click: function($event) {
                                                return _vm.editTransaction(item)
                                              }
                                            }
                                          },
                                          [_vm._v("Edit")]
                                        ),
                                        item.status === "pending"
                                          ? _c(
                                              "v-btn",
                                              {
                                                staticClass: "ml-2 white--text",
                                                attrs: { color: "green" },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.updateStatus(
                                                      item,
                                                      "approved"
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v("Approve")]
                                            )
                                          : _vm._e(),
                                        item.status === "pending"
                                          ? _c(
                                              "v-btn",
                                              {
                                                staticClass: "ml-2 white--text",
                                                attrs: { color: "red" },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.updateStatus(
                                                      item,
                                                      "rejected"
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v("Reject")]
                                            )
                                          : _vm._e()
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            })
                          ]
                    ],
                    2
                  )
                }),
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "600px" },
          model: {
            value: _vm.editDialog,
            callback: function($$v) {
              _vm.editDialog = $$v
            },
            expression: "editDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _c("span", { staticClass: "headline" }, [
                  _vm._v("Edit Transaction")
                ])
              ]),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-form",
                    { ref: "editForm" },
                    [
                      _c("v-text-field", {
                        attrs: { label: "Amount", required: "" },
                        model: {
                          value: _vm.editedTransaction.amount,
                          callback: function($$v) {
                            _vm.$set(_vm.editedTransaction, "amount", $$v)
                          },
                          expression: "editedTransaction.amount"
                        }
                      }),
                      _c("v-select", {
                        attrs: {
                          items: _vm.statusOptions,
                          label: "Status",
                          required: ""
                        },
                        model: {
                          value: _vm.editedTransaction.status,
                          callback: function($$v) {
                            _vm.$set(_vm.editedTransaction, "status", $$v)
                          },
                          expression: "editedTransaction.status"
                        }
                      }),
                      _c("v-select", {
                        attrs: {
                          items: _vm.typeOptions,
                          label: "Type",
                          required: ""
                        },
                        model: {
                          value: _vm.editedTransaction.type,
                          callback: function($$v) {
                            _vm.$set(_vm.editedTransaction, "type", $$v)
                          },
                          expression: "editedTransaction.type"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "blue darken-1", text: "" },
                      on: { click: _vm.saveTransaction }
                    },
                    [_vm._v("Save")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "grey", text: "" },
                      on: {
                        click: function($event) {
                          _vm.editDialog = false
                        }
                      }
                    },
                    [_vm._v("Cancel")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "600px" },
          model: {
            value: _vm.showAddDepositDialog,
            callback: function($$v) {
              _vm.showAddDepositDialog = $$v
            },
            expression: "showAddDepositDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _c("span", { staticClass: "headline" }, [_vm._v("Add Deposit")])
              ]),
              _c(
                "v-card-text",
                [
                  _c("v-text-field", {
                    attrs: { label: "Search User" },
                    on: { input: _vm.searchVenmo },
                    model: {
                      value: _vm.userSearch,
                      callback: function($$v) {
                        _vm.userSearch = $$v
                      },
                      expression: "userSearch"
                    }
                  }),
                  _vm.userResults.length
                    ? _c(
                        "v-list",
                        _vm._l(_vm.userResults, function(user) {
                          return _c(
                            "v-list-item",
                            { key: user.id },
                            [
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(
                                      " " +
                                        _vm._s(user.nickname) +
                                        " - " +
                                        _vm._s(user.firstname) +
                                        " " +
                                        _vm._s(user.lastname) +
                                        " "
                                    )
                                  ]),
                                  _c("v-text-field", {
                                    attrs: { label: "Amount", type: "number" },
                                    model: {
                                      value: user.amount,
                                      callback: function($$v) {
                                        _vm.$set(user, "amount", $$v)
                                      },
                                      expression: "user.amount"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-list-item-action",
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        color: "primary",
                                        disabled: !user.amount
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.saveDeposit(user)
                                        }
                                      }
                                    },
                                    [_vm._v("Save")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "grey", text: "" },
                      on: {
                        click: function($event) {
                          _vm.showAddDepositDialog = false
                        }
                      }
                    },
                    [_vm._v("Close")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }