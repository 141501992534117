<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title @click="filterarrow = !filterarrow">
            <div class="mx-auto">
              <span>Filter</span>
              <v-icon class="collapse-arrow" :class="{ 'collapse-arrow-down': filterarrow }">
                {{ filterarrow ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
              </v-icon>
            </div>
          </v-card-title>
          <v-expand-transition>
            <div v-show="filterarrow">
              <v-row class="ml-4">
                <v-col cols="6">
                  <v-checkbox v-model="statusFilters" value="pending" label="Pending"></v-checkbox>
                </v-col>
                <v-col cols="6">
                  <v-checkbox v-model="statusFilters" value="approved" label="Approved"></v-checkbox>
                </v-col>
                <v-col cols="6">
                  <v-checkbox v-model="statusFilters" value="rejected" label="Rejected"></v-checkbox>
                </v-col>
              </v-row>
              <v-row class="ml-4">
                <v-col cols="3">
                  <v-menu v-model="startdatepicker" :close-on-content-click="false">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="startdateDisplay" label="From Date" prepend-icon="mdi-calendar" readonly
                        clearable v-bind="attrs" v-on="on" @clear="clearStartDate()"></v-text-field>
                    </template>
                    <v-date-picker v-model="startdate" @input="setStartDate"
                      :max="new Date().toISOString().substr(0, 10)"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="3">
                  <v-menu v-model="enddatepicker" :close-on-content-click="false">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="enddateDisplay" label="To Date" prepend-icon="mdi-calendar" readonly
                        clearable v-bind="attrs" v-on="on" @clear="clearEndDate()"></v-text-field>
                    </template>
                    <v-date-picker v-model="enddate" @input="setEndDate"
                      :max="new Date().toISOString().substr(0, 10)"></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row justify="end">
                <v-col cols="12">
                  <v-btn class="white--text" color="black" @click="applyFilters">Set Filters</v-btn>
                  <v-btn class="white--text ml-2" color="grey" @click="clearDates">Clear Dates</v-btn>
                </v-col>
              </v-row>
            </div>
          </v-expand-transition>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-tabs v-model="tab">
          <v-tab v-for="tab in tabs" :key="tab.name">{{ tab.name }}</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item v-for="tab in tabs" :key="tab.name">
            <v-data-table v-if="tab.name !== 'Deposits'" :headers="headers" :items="transactions" :items-per-page="itemsPerPage" :loading="loading"
              :server-items-length="totalItems" @pagination="handlePagination">
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title>{{ tab.name }}</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details
                    @input="applyFilters"></v-text-field>
                </v-toolbar>
              </template>
              <!-- <template v-slot:header.amount="{ header }">
                <span class="right-align">{{ header.text }}</span>
              </template> -->
              <template v-slot:item.amount="{ item }">
                <span>{{ formatAmount(item.amount) }}</span>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-btn @click="editTransaction(item)">Edit</v-btn>
                <v-btn v-if="item.status === 'pending'" color="green" @click="updateStatus(item, 'approved')"
                  class="ml-2 white--text"  :loading="loadingButtonId[item.id] && loadingButtonId[item.id].approve"
                  :disabled="loadingButtonId[item.id] && loadingButtonId[item.id].approve">Approve</v-btn>
                <v-btn v-if="item.status === 'pending'" color="red" @click="updateStatus(item, 'rejected')"
                  class="ml-2 white--text" :loading="loadingButtonId[item.id] && loadingButtonId[item.id].reject"
                  :disabled="loadingButtonId[item.id] && loadingButtonId[item.id].reject">Reject</v-btn>
              </template>
            </v-data-table>
            <template v-else>
              <v-toolbar flat>
                <v-toolbar-title>{{ tab.name }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="showAddDepositDialog = true">Add Deposit</v-btn>
              </v-toolbar>
              <v-data-table :headers="headers" :items="transactions" :items-per-page="itemsPerPage" :loading="loading"
                :server-items-length="totalItems" @pagination="handlePagination">
                <template v-slot:top>
                  <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details
                    @input="applyFilters"></v-text-field>
                </template>
                <template v-slot:item.amount="{ item }">
                  <span>{{ formatAmount(item.amount) }}</span>
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-btn @click="editTransaction(item)">Edit</v-btn>
                  <v-btn v-if="item.status === 'pending'" color="green" @click="updateStatus(item, 'approved')"
                    class="ml-2 white--text" :loading="loadingButtonId[item.id] && loadingButtonId[item.id].approve"
                    :disabled="loadingButtonId[item.id] && loadingButtonId[item.id].approve">Approve</v-btn>
                  <v-btn v-if="item.status === 'pending'" color="red" @click="updateStatus(item, 'rejected')"
                    class="ml-2 white--text" :loading="loadingButtonId[item.id] && loadingButtonId[item.id].reject"
                    :disabled="loadingButtonId[item.id] && loadingButtonId[item.id].reject">Reject</v-btn>
                </template>
              </v-data-table>
            </template>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>

    <!-- Edit Transaction Modal -->
    <v-dialog v-model="editDialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Edit Transaction</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="editForm">
            <v-text-field v-model="editedTransaction.amount" label="Amount" required></v-text-field>
            <v-select v-model="editedTransaction.status" :items="statusOptions" label="Status" required></v-select>
            <v-select v-model="editedTransaction.type" :items="typeOptions" label="Type" required></v-select>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" :loading="editLoading" :disabled="editLoading" @click="saveTransaction">
            <template v-slot:loader>
              <v-progress-circular indeterminate color="white" size="20"></v-progress-circular>
            </template>
            Save
          </v-btn>
          <v-btn color="grey" @click="editDialog = false">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Add Deposit Modal -->
    <v-dialog v-model="showAddDepositDialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Add Deposit</span>
        </v-card-title>
        <v-card-text>
          <v-text-field v-model="userSearch" label="Search User" @input="searchVenmo"></v-text-field>
          <v-list v-if="userResults.length">
            <v-list-item v-for="user in userResults" :key="user.id">
              <v-list-item-content>
                <v-list-item-title>
                  {{ user.nickname }} - {{ user.firstname }} {{ user.lastname }}
                </v-list-item-title>
                <v-text-field v-model="user.amount" label="Amount" type="number"></v-text-field>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn color="primary" :loading="depositLoading" :disabled="!user.amount || depositLoading" @click="saveDeposit(user)">
                  <template v-slot:loader>
                    <v-progress-circular indeterminate color="white" size="20"></v-progress-circular>
                  </template>
                  Save</v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" text @click="showAddDepositDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import TransactionService from '@/services/TransactionService'
import UserService from '@/services/UserService'

export default {
  data() {
    return {
      tab: 0,
      tabs: [
        { name: 'All Transactions' },
        { name: 'Deposits' },
        { name: 'Withdrawals' },
        { name: 'Refunds' },
        { name: 'Wagers' }
      ],
      headers: [
        // { text: 'ID', value: 'id', sortable: false },
        { text: 'Venmo Handle', value: 'venmo_handle', sortable: false },
        { text: 'Amount', value: 'amount', sortable: false },
        { text: 'Status', value: 'status', sortable: false },
        { text: 'Type', value: 'type', sortable: false },
        { text: 'Created', value: 'created', sortable: false },
        { text: 'Updated', value: 'updated', sortable: false },
        { text: 'Actions', value: 'actions', sortable: false }
      ],
      transactions: [],
      totalItems: 0,
      itemsPerPage: 10,
      page: 1,
      search: '',
      statusFilters: [],
      typeFilters: [],
      startdate: null,
      enddate: null,
      startdateDisplay: '',
      enddateDisplay: '',
      filterarrow: false,
      loading: false,
      depositLoading: false,
      editLoading: false,
      loadingButtonId: {},
      showAddDepositDialog: false,
      userSearch: '',
      userResults: [],
      editDialog: false,
      editedTransaction: {},
      statusOptions: ['pending', 'approved', 'rejected'],
      typeOptions: ['deposit', 'withdrawal', 'refund', 'wager'],
      startdatepicker: false,
      enddatepicker: false
    }
  },
  watch: {
    showAddDepositDialog(newVal) {
      if (!newVal) {
        // Clear the search field and user results when the modal is closed
        this.userSearch = '';
        this.userResults = [];
      }
    },
    tab: 'applyTabFilter',
    startdateDisplay(newVal) {
      if (!newVal) {
        this.clearStartDate()
      }
    },
    enddateDisplay(newVal) {
      if (!newVal) {
        this.clearEndDate()
      }
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.getTransactions()
    },
    getTransactions() {
      this.loading = true
      const params = {
        status: this.statusFilters,
        type: this.typeFilters.length > 0 ? this.typeFilters : this.getTypeFilterByTab(),
        startdate: this.startdate,
        enddate: this.enddate,
        search: this.search,
        page: this.page,
        itemsPerPage: this.itemsPerPage
      }
      TransactionService.getFilteredAdminTransactions(params).then(
        (response) => {
          this.transactions = response.data.transactions
          this.totalItems = response.data.total
          this.loading = false
        },
        (error) => {
          console.error(error)
          this.loading = false
        }
      )
    },
    applyFilters() {
      this.page = 1
      this.getTransactions()
    },
    applyTabFilter() {
      this.typeFilters = this.getTypeFilterByTab()
      this.applyFilters()
    },
    getTypeFilterByTab() {
      switch (this.tabs[this.tab].name) {
        case 'Deposits':
          return ['deposit']
        case 'Withdrawals':
          return ['withdrawal']
        case 'Refunds':
          return ['refund']
        case 'Wagers':
          return ['wager']
        default:
          return []
      }
    },
    formatAmount(amount) {
      return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amount)
    },
    handlePagination(pagination) {
      this.page = pagination.page
      this.itemsPerPage = pagination.itemsPerPage
      this.getTransactions()
    },
    setStartDate(date) {
      this.startdatepicker = false
      this.startdate = new Date(date).toISOString().split('T')[0] + 'T00:00:00'
      this.startdateDisplay = date
    },
    setEndDate(date) {
      this.enddatepicker = false
      this.enddate = new Date(date).toISOString().split('T')[0] + 'T23:59:59'
      this.enddateDisplay = date
    },
    clearStartDate() {
      console.log('clear start called')
      this.startdate = null
      this.startdateDisplay = ''
    },
    clearEndDate() {
      console.log('clear end called')
      this.enddate = null
      this.enddateDisplay = ''
    },
    editTransaction(transaction) {
      this.editedTransaction = { ...transaction }
      this.editDialog = true
    },
    saveTransaction() {
      if (UserService.isAdmin()) {
        this.editLoading = true
        const approver = UserService.getUser()
        console.log(approver)
        this.editedTransaction.approver_id = approver.id
        TransactionService.updateTransaction(this.editedTransaction.id, this.editedTransaction).then(
          () => {
            this.editDialog = false
            this.getTransactions()
          },
          (error) => {
            console.error(error)
          }
        ).finally(() => {
          this.editTransaction = false
        })
      }
    },
    updateStatus(transaction, status) {
      if (UserService.isAdmin()) {
        if (!this.loadingButtonId[transaction.id]) this.loadingButtonId[transaction.id] = {}
        const actionKey = status === 'approved' ? 'approve' : 'reject'
        this.loadingButtonId[transaction.id][actionKey] = true
        const approver = UserService.getUser()
        TransactionService.updateTransactionStatus(transaction.id, { status, approver_id: approver.id }).then(
          () => {
            this.getTransactions()
          },
          (error) => {
            console.error(error)
          }
        ).finally(() => {
          if (this.loadingButtonId[transaction.id]) {
            this.loadingButtonId[transaction.id][actionKey] = false
          }
        })
      }
    },
    clearDates() {
      this.clearStartDate()
      this.clearEndDate()
      this.applyFilters()
    },
    searchVenmo() {
      if (!this.userSearch.trim()) {
        // Clear userResults if the search field is cleared
        this.userResults = []
        return
      }

      UserService.searchVenmo(this.userSearch).then(
        (response) => {
          this.userResults = response
        },
        (error) => {
          console.error(error)
        }
      );
    },
    saveDeposit(user) {
      if (UserService.isAdmin()) {
        const approver = UserService.getUser()
        this.depositLoading = true
        TransactionService.addDeposit({ user_id: user.id, amount: user.amount, status: 'approved', approverid: approver.id }).then(
          () => {
            this.showAddDepositDialog = false
            this.getTransactions()
          },
          (error) => {
            console.error(error)
          }
        ).finally(() => {
          this.depositLoading = false
        })
      }
    }
  }
}
</script>

<style scoped>
.collapse-arrow {
  transition: transform 0.3s ease;
}

.collapse-arrow-down {
  transform: rotate(180deg);
}

.right-align {
  text-align: right;
  display: block; /* Ensures the text alignment affects the line */
  width: 100%; /* Takes full width to push content to the right */
}
</style>
