var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-container",
        {
          staticClass: "overflow-y-auto pa-0 my-0",
          staticStyle: { "min-height": "200px", "overflow-x": "hidden" }
        },
        [
          _c(
            "div",
            [
              _vm._l(_vm.messages, function(m) {
                return _c(
                  "v-row",
                  {
                    key: m.id,
                    staticClass: "mt-0 mb-1",
                    attrs: {
                      justify: m.user_id == m.offeredby_id ? "end" : "start"
                    }
                  },
                  [
                    _c(
                      "v-col",
                      {
                        staticClass: "py-0",
                        class:
                          m.user_id == m.offeredby_id
                            ? "text-right"
                            : "text-left"
                      },
                      [
                        _c("div", { staticClass: "timestamp" }, [
                          _vm._v(_vm._s(m.created))
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "message",
                            class:
                              m.user_id == m.offeredby_id
                                ? "message-from"
                                : "message-to"
                          },
                          [_c("div", [_vm._v(_vm._s(m.message))])]
                        ),
                        _c("div", { staticClass: "timestamp" }, [
                          _vm._v(_vm._s(m.sendername))
                        ])
                      ]
                    )
                  ],
                  1
                )
              }),
              _vm.messages.length == 0
                ? _c("v-row", [_c("v-col", [_vm._v(" No messages ")])], 1)
                : _vm._e()
            ],
            2
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }