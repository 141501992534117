import axios from 'axios'
import UserService from '@/services/UserService'

const ChatService = {
  async getChatHistory (wager_id) {
    await UserService.authHeader()
    var data = {
      wager_id: wager_id,
    }
    return axios({
      url: UserService.baseUrl() + 'chat/admin/wager/messagehistory',
      data: JSON.stringify(data),
      method: 'POST'
    })
  },
}

export default ChatService
