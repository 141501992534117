<style scoped></style>
<template>
  <div style="max-width: 600px;" class="container">

    <v-form ref="theLoginForm">
      <v-container fluid>
        <v-row justify="center">
          <v-col cols="12">
            <v-text-field
              dense
              label="Email"
              v-model="email"
              required
              :disabled="sending"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-col cols="12">
            <v-text-field
              dense
              label="Password"
              v-model="password"
              type="password"
              required
              :disabled="sending"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-col cols="12">
            <v-btn
              elevation="2"
              :disabled="sending || formInvalid()"
              @click="login"
            >Log In</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </div>
</template>
<script>
import UserService from '@/services/UserService'

export default {
  name: 'LoginForm',
  data: function () {
    return {
      email: '',
      password: '',
      sending: false
    }
  },

  created: function () {
    if (UserService.loggedIn()) {
      this.$router.push({ path: '/wagers' })
    }
  },

  methods: {
    formInvalid () {
      let isinvalid = false
      if (!this.email.length || !this.password.length) {
        isinvalid = true
      }
      return isinvalid
    },

    async login () {
      // vue included component in main.js
      this.sending = true
      UserService.login(this.email, this.password).then(
        (response) => {
          this.sending = false
          console.log(response)

          if (response && !response.error) {
            this.$emit('updatelogin')
            this.$router.push('/wagers', () => {
              this.$notify({
                group: 'notices',
                title: 'Login Successful',
                text: 'You are now logged in.',
                type: 'info'
              })
            })
          } else {
            alert(response.error)
          }
        },
        (failed) => {
          this.sending = false
          // https://github.com/euvl/vue-notification
          console.dir(failed)
          alert(failed.response.data.error)
        }
      )
    }
  }
}
</script>
