var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { attrs: { id: "app" } },
    [
      _c("notifications", {
        staticClass: "notifications",
        attrs: { group: "errors", duration: -1, ignoreDuplicates: true, max: 1 }
      }),
      _c("notifications", {
        staticClass: "notifications",
        attrs: { group: "notices", ignoreDuplicates: true, max: 1 }
      }),
      _c("loader-overlay"),
      _c(
        "v-app-bar",
        { attrs: { app: "", dense: "", flat: "" } },
        [
          _c(
            "v-menu",
            {
              attrs: {
                bottom: "",
                "nudge-bottom": "",
                "offset-y": "",
                "min-width": "150",
                disabled: !_vm.isLoggedIn
              },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    return [_c("v-app-bar-nav-icon", _vm._g({}, on))]
                  }
                }
              ])
            },
            [
              _c(
                "v-list",
                [
                  _c("v-divider"),
                  _c("v-list-item", { attrs: { to: "/transactions" } }, [
                    _vm._v("Transactions")
                  ]),
                  _c("v-divider"),
                  _c("v-list-item", { attrs: { to: "/wagers" } }, [
                    _vm._v("Wagers")
                  ]),
                  _c("v-divider"),
                  _c("v-list-item", { attrs: { to: "/games" } }, [
                    _vm._v("Games")
                  ]),
                  _c("v-divider"),
                  _c("v-list-item", { attrs: { to: "/progress-summary" } }, [
                    _vm._v("Progress Summary")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c("v-toolbar-title", [_vm._v("Vig Admin")]),
          _c("v-spacer"),
          _vm.isLoggedIn
            ? _c(
                "v-btn",
                {
                  attrs: { dense: "", small: "", color: "primary" },
                  on: { click: _vm.logout }
                },
                [_vm._v(" Log Out ")]
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-main",
        [
          _c("router-view", {
            on: {
              updatelogin: function($event) {
                _vm.key++
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }