import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Transactions from '../views/Transactions.vue'
import Wagers from '../views/Wagers.vue'
import Games from '../views/Games.vue'
import ProgressSummary from '../views/ProgressSummary.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/transactions',
    name: 'Transactions',
    component: Transactions
  },
  {
    path: '/wagers',
    name: 'Wagers',
    component: Wagers
  },
  {
    path: '/progress-summary',
    name: 'Progress Summary',
    component: ProgressSummary
  },
  {
    path: '/games',
    name: 'Games',
    component: Games
  }
]

const router = new VueRouter({
  routes
})

export default router
