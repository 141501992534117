var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { overflow: "hidden" } },
    [
      _vm.user
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-card",
                    [
                      _c("v-card-title", [
                        _vm._v(" Suspend " + _vm._s(_vm.user.name) + " ")
                      ]),
                      _c(
                        "v-card-text",
                        [
                          _vm._v(
                            " How long do you want to suspend " +
                              _vm._s(_vm.user.name) +
                              " (" +
                              _vm._s(_vm.user.username) +
                              ") ? "
                          ),
                          _c("v-select", {
                            attrs: {
                              label: "Suspension duration",
                              items: _vm.options,
                              "item-value": "val",
                              "item-text": "label"
                            },
                            model: {
                              value: _vm.duration,
                              callback: function($$v) {
                                _vm.duration = $$v
                              },
                              expression: "duration"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-card-actions",
                        [
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.cancel()
                                }
                              }
                            },
                            [_vm._v("Cancel")]
                          ),
                          _c(
                            "v-btn",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.suspend()
                                }
                              }
                            },
                            [_vm._v("Suspend")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }