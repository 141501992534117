<template>
  <div style="overflow:hidden;">
    <v-row v-if="user">
      <v-col>
        <v-card>
          <v-card-title>
            Suspend {{ user.name }}
          </v-card-title>
          <v-card-text>
            How long do you want to suspend {{ user.name }} ({{ user.username }}) ?
            <v-select
              label="Suspension duration"
              v-model="duration"
              :items="options"
              item-value="val"
              item-text="label"
            ></v-select>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="cancel()">Cancel</v-btn>
            <v-btn @click="suspend()">Suspend</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import UserService from '@/services/UserService'
import WagerService from '@/services/WagerService'

export default {
  data: () => {
    return {
      duration: 30,
      options: [
        { val: 1, label: "1 Day" },
        { val: 2, label: "2 Days" },
        { val: 7, label: "7 Days" },
        { val: 30, label: "30 Days" },
        { val: 60, label: "60 Days" },
        { val: 90, label: "90 Days" },
        { val: -1, label: "Indefinitely" }
      ]
    }
  },
  props: {
    user: {
      type: Object,
      default: () => {
        return {
          id: -1,
          name: '',
          username: '',
          wager_id: -1
        }
      }
    }
  },
  methods: {
    cancel: function () {
      this.$emit('cancel')
    },
    suspend: function () {
      UserService.suspendUser(this.user.id, this.duration).then(
        (response) => {
          console.log(response)

          var noteMessage = ''
          if (this.duration > 0) {
            noteMessage = this.user.name + ' was suspended for a duration of ' + this.duration + ' days'
          } else {
            noteMessage = this.user.name + ' has been suspended indefinitely'
          }
          var noteData = {
            wager_id: this.user.wager_id,
            note: noteMessage
          }

          WagerService.savenote(noteData).then(
            (res) => {
              console.log(res)
              this.$emit('update')
            }
          )

        }
      )
    }
  }
}
</script>