import { render, staticRenderFns } from "./Suspend.vue?vue&type=template&id=2cbde63e&"
import script from "./Suspend.vue?vue&type=script&lang=js&"
export * from "./Suspend.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCard,VCardActions,VCardText,VCardTitle,VCol,VRow,VSelect,VSpacer})


/* hot reload */
if (module.hot) {
  var api = require("/builds/websites/vigzero/vig-zero-admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2cbde63e')) {
      api.createRecord('2cbde63e', component.options)
    } else {
      api.reload('2cbde63e', component.options)
    }
    module.hot.accept("./Suspend.vue?vue&type=template&id=2cbde63e&", function () {
      api.rerender('2cbde63e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/widget/Suspend.vue"
export default component.exports