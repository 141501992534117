<style>
tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.1);
}
</style>
<template>
  <v-container>
        <v-data-table
          dense
          :headers="headers"
          :items="results"
          :footer-props="{ itemsPerPageOptions:[ -1, 15, 25, 50 ] }"
          :header-props="{ sortIcon: null }"
          :items-per-page="25"
          item-key="id"
          class="elevation-1 mt-0"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Progress Summary</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
          </template>

          <template v-slot:item="{ item }">
            <tr>
              <td>{{ item.week }}</td>
              <td>{{ item.ending }}</td>
              <td class="text-right">{{ item.invites | commanum }}</td>
              <td class="text-right">{{ item.SWconv | commanum }}</td>
              <td class="text-right">{{ item.SWpct }}</td>
              <td class="text-right">{{ item.conv | commanum }}</td>
              <td class="text-right">{{ item.CNVpct }}</td>
              <td class="text-right">{{ item.users | commanum }}</td>
              <td class="text-right">{{ item.organicusers | commanum }}</td>
              <td class="text-right">{{ item.affiliateusers | commanum }}</td>
              <td class="text-right">{{ item.UtoIpct }}</td>
              <td class="text-right">{{ item.numOffers | commanum }}</td>
              <td class="text-right">${{ item.offered$ | commanum }}</td>
              <td class="text-right">{{ item.numWagers | commanum }}</td>
              <td class="text-right">{{ item.pctTaken }}</td>
              <td class="text-right">${{ item.wagered$ | commanum }}</td>
              <td class="text-right">${{ item.avgbet | commanum }}</td>
              <td class="text-right">{{ item.numWagersGroup | commanum }}</td>
              <td class="text-right">{{ item.wageredGroup | commanum }}</td>
            </tr>
          </template>          
        </v-data-table>

  </v-container>
</template>
<script>
import UserService from '@/services/UserService'
import ProgressService from '@/services/ProgressService'

export default {
  name: 'ProgressSummary',
  data: function () {
    return {
      results: [],
      headers: [
        { text: 'Week', value: 'week', align: 'center' },
        { text: 'Ending', value: 'ending', align: 'center' },
        { text: 'Invites', value: 'invites', align: 'right' },
        { text: 'Same Week Conversions', value: 'SWconv', align: 'right' },
        { text: 'Same Week %', value: 'SWpct', align: 'right' },
        { text: 'Conversions', value: 'conv', align: 'right' },
        { text: 'Conversion %', value: 'CNVpct', align: 'right' },
        { text: 'Users', value: 'users', align: 'right' },
        { text: 'Organic', value: 'organicusers', align: 'right' },
        { text: 'Affiliate', value: 'affiliateusers', align: 'right' },
        { text: 'Users/Invites %', value: 'UtoIpct', align: 'right' },
        { text: 'Offers', value: 'numOffers', align: 'right' },
        { text: 'Offered', value: 'offered$', align: 'right' },
        { text: 'Wagers', value: 'numWagers', align: 'right' },
        { text: '% Taken', value: 'pctTaken', align: 'right' },
        { text: 'Wagered', value: 'wagered$', align: 'right' },
        { text: 'Avg Bet', value: 'avgbet', align: 'right' },
        { text: 'Wagers Group', value: 'numWagersGroup', align: 'right' },
        { text: 'Wagered Group', value: 'wageredGroup', align: 'right' },
      ],
    }
  },
  mounted: function () {
    this.get()
  },
  methods: {
    logout () {
      return UserService.logout()
    },

    async get () {
      this.$updateloading(1)
      await ProgressService.get().then(
        (response) => {
          this.results = response.data.results
          this.$updateloading(-1)
        },
        (error) => {
          alert('Failed to load report!')
          console.dir(error)
          this.$updateloading(-1)
        }
      )
    },

  },
  filters: {
    commanum (str) {
      return Number(str).toLocaleString()
    }
  }
}
</script>

