import router from '@/router'
import axios from 'axios'
import moment from 'moment'
import Vue from 'vue'
import Notifications from 'vue-notification'

Vue.use(Notifications)

const UserService = {

  baseUrl () {
    if (typeof process !== 'undefined' && process.env.NODE_ENV === 'development') {
      return 'https://vig-zero-api.dev.aimitservices.com/'
    } else if (typeof process !== 'undefined' && process.env.NODE_ENV === 'staging') {
      return process.env.VUE_APP_STAGING_API_URL
    } else {
      return 'https://api.vigzero.com/'
    }
  },

  async searchVenmo (searchterm) {
    return axios({
      url: this.baseUrl() + 'community/searchvenmo',
      method: 'post',
      data: JSON.stringify({ searchterm: searchterm })
    }).then(async response => {
      return response.data
    })
  },

  async verifyUser () {
    const user = this.getUser()
    if (!user || !user.id) {
      return
    }

    return axios({
      url: this.baseUrl() + 'account/verify',
      method: 'post',
      data: { id: user.id },
      headers: {
        common: {
          Authorization: 'Bearer ' + this.getToken()
        }
      }
    })
  },

  setLocalStorages (user, date = new Date()) {
    localStorage.setItem('user', JSON.stringify(user))
    localStorage.setItem('lastEntry', JSON.stringify(date))
  },

  needToRefreshAuthData () {
    const lastEntry = JSON.parse(localStorage.getItem('lastEntry'))
    const curt = new Date()
    return (!lastEntry || moment(curt).diff(moment(lastEntry), 'hours') >= 12)
  },

  /**
   * Refreshes the vigzero user data, this also
   * verifies if the account is still valid
   */
  async refreshAuthData () {
    const userResponse = await this.verifyUser()
    if (userResponse) {
      this.setLocalStorages(userResponse.data)
      return true
    } else {
      this.redirectToLogin('Your session has expired, please login again.')
      return false
    }
  },

  async authHeader () {
    const user = this.getToken()
    // if no user storage, return null
    if (!user) {
      this.redirectToLogin('Your session has expired. Please login again.')
      return
    }
    // check if we need to refresh user token
    // if true, refresh them
    var needRefresh = this.needToRefreshAuthData()
    if (needRefresh) {
      const result = await this.refreshAuthData()
      if (result === false) {
        return false
      }
    }

    axios.defaults.baseURL = this.baseUrl()
    axios.defaults.headers.common.Authorization = 'Bearer ' + user

    // return authorization header with jwt token
    return {
      Authorization: 'Bearer ' + user
    }
  },

  getToken () {
    const user = this.getUserObject()
    if (user === null) {
      this.logout()
      return null
    } else {
      return user.key
    }
  },

  async login (username, password) {
    return axios({
      url: this.baseUrl() + 'login',
      method: 'post',
      data: JSON.stringify({ email: username, password: password })
    }).then(async response => {
      // login successful if there's a jwt token in the response
      if (response && response.data && response.data.key && response.data.info.isadmin) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        this.setLocalStorages(response.data)
        await this.authHeader()
        return response.data
      } else if (response && response.data && response.data.key) {
        return { error: 'Invalid login.' }
      } else {
        return response.data
      }
    })
  },

  logout () {
    // remove user from local storage to log user out
    localStorage.clear()
    // localStorage.removeItem('user');
  },

  loggedIn () {
    const user = JSON.parse(localStorage.getItem('user'))
    if (user === null) {
      return false
    } else if (typeof user.key === 'undefined') {
      this.logout()
    } else {
      return true // todo verify token and expiration stuff
    }
  },

  isAdmin () {
    const user = JSON.parse(localStorage.getItem('user'))
    if (user === null) {
      return false
    } else if (typeof user.key === 'undefined') {
      this.logout()
    } else {
      return user.info.isadmin
    }
  },

  getUserObject () {
    const temp = localStorage.getItem('user')
    if (temp !== null) {
      return JSON.parse(localStorage.getItem('user'))
    } else {
      return null
    }
  },

  getUser () {
    const temp = this.getUserObject()
    if (temp !== null) {
      return temp.info
    } else {
      return null
    }
  },

  handleResponse (response) {
    const self = this
    return response.text().then(text => {
      const data = text && JSON.parse(text)
      if (!response.ok) {
        if (response.status === 401) {
          // auto logout if 401 response returned from api
          self.logout()
          // location.reload(true);
        }

        const error = (data && data.error) || response.statusText
        return Promise.reject(error)
      }

      return Promise.resolve(data)
    })
  },

  redirectToLogin (message) {
    this.logout()
    router.push('/', () => {
      Vue.notify({
        group: 'notices',
        title: 'Logout Complete',
        text: message,
        type: 'danger'
      })
    })
  },

  suspendUser (userId, duration) {
    this.authHeader()
    var data = {
      user_id: userId,
      duration: duration
    }
    return axios({
      url: this.baseUrl() + 'disputes/suspend',
      data: JSON.stringify(data),
      method: 'POST'
    })
  }

}

export default UserService
