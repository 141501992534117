import axios from 'axios'
import UserService from '@/services/UserService'

const GameService = {

  async search (filters, perpage, page, sort) {
    await UserService.authHeader()
    var data = {
      filters: filters,
      perpage: perpage,
      page: page,
      sort: sort
    }
    return axios({
      url: UserService.baseUrl() + 'games/search',
      data: JSON.stringify(data),
      method: 'POST'
    })
  },

  async finalize (game) {
    await UserService.authHeader()
    return axios({
      url: UserService.baseUrl() + 'games/admin/finalize',
      data: JSON.stringify(game),
      method: 'POST'
    })
  },

  async finalizeAllGames (games) {
    await UserService.authHeader()
    return axios({
      url: UserService.baseUrl() + 'games/admin/finalize/all',
      data: JSON.stringify(games),
      method: 'POST'
    })
  }

}

export default GameService
