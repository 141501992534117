<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="800px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
          small
        >
          {{ action }}
        </v-btn>
      </template>

      <v-card>
        <v-card-title>
          <span class="headline">Wager #{{ wager.id }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="6">
                <h1>Winner</h1>
                <span v-if="wager.winner_id === wager.offeredby_id">
                  {{ wager.offerernickname }} ({{ wager.offeredby_id }})<br>
                  {{ wager.offerer }}<br>
                  ${{ wager.offeredbyamount }}
                </span>
                <span v-else>
                  {{ wager.takernickname }} ({{ wager.takenby_id }})<br>
                  {{ wager.taker }}<br>
                  ${{ wager.takenbyamount }}
                </span>
                <br>
                <v-text-field
                  label="Payment Confirmed"
                  placeholder="YYYY-MM-DD HH:MM:SS"
                  hide-details
                  v-model="paymentreceived"
                  clearable
                  @focus="setDateIfEmptyOnFirstTouch('paymentreceived')"
                >
                </v-text-field>
                <br>
                <v-btn
                  small
                  color="primary"
                  @click="updatePaymentConfirmation"
                >Update Confirmation</v-btn>
              </v-col>
              <v-col cols="6">
                <h1>Loser</h1>
                <span v-if="wager.loser_id === wager.offeredby_id">
                  {{ wager.offerernickname }} ({{ wager.offeredby_id }})<br>
                  {{ wager.offerer }}<br>
                  ${{ wager.offeredbyamount }}
                </span>
                <span v-else>
                  {{ wager.takernickname }} ({{ wager.takenby_id }})<br>
                  {{ wager.taker }}<br>
                  ${{ wager.takenbyamount }}
                </span>
                <br>
                <v-text-field
                  label="Payment Sent"
                  placeholder="YYYY-MM-DD HH:MM:SS"
                  hide-details
                  v-model="paymentsent"
                  clearable
                  @focus="setDateIfEmptyOnFirstTouch('paymentsent')"
                >
                </v-text-field>
                <br>
                <v-btn
                  small
                  color="primary"
                  @click="updatePaymentSent"
                >Update Payment Sent</v-btn>
              </v-col>
            </v-row>

          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="close"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import WagerService from '@/services/WagerService'

export default {
  name: 'WagerPayments',
  props: {
    wager: Object,
    action: String
  },
  data: () => ({
    changesmade: false,
    dialog: false,
    paymentsent: null,
    paymentreceived: null,
    paymentsenttouched: false,
    paymentreceivedtouched: false
  }),
  mounted: function () {
    if (this.wager && this.wager.paymentsent) {
      this.paymentsent = this.wager.paymentsent
      if (this.wager.paymentreceived) {
        this.paymentreceived = this.wager.paymentreceived
      }
    }
  },
  methods: {
    setDateIfEmptyOnFirstTouch (varName) {
      const touchVar = varName + 'touched'
      if (!this[touchVar] && !this[varName]) {
        const dt = new Date()
        this[varName] = dt.getFullYear() + '-' + (dt.getMonth() + 1).toString().padStart(2, '0') + '-' + dt.getDate().toString().padStart(2, '0') + ' ' + dt.getHours().toString().padStart(2, '0') + ':' + dt.getMinutes().toString().padStart(2, '0') + ':00'
        this[touchVar] = true
      }
    },

    updatePaymentConfirmation () {
      this.$updateloading(1)
      WagerService.updatePaymentConfirmation(this.wager.id, this.paymentreceived).then(
        (response) => {
          this.paymentreceived = response.data.received
          this.changesmade = true
          this.$updateloading(-1)
        },
        (error) => {
          this.$updateloading(-1)
          alert('Failed to update payment confirmation!')
          console.dir(error)
        }
      )
    },

    updatePaymentSent () {
      this.$updateloading(1)
      WagerService.updatePaymentSent(this.wager.id, this.paymentsent).then(
        (response) => {
          this.paymentsent = response.data.sent
          this.changesmade = true
          this.$updateloading(-1)
        },
        (error) => {
          this.$updateloading(-1)
          alert('Failed to update payment sent!')
          console.dir(error)
        }
      )
    },
    close () {
      this.dialog = false
      this.paymentsenttouched = false
      this.paymentreceivedtouched = false
      if (this.changesmade) {
        this.$emit('update')
      }
    }
  }
}
</script>
